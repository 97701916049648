import { BACKEND_URL } from './env';
import { IErrorResponse } from './interface';

interface IUserParams {
  email: string;
  password: string;
  code?: string;
}

export interface IUserResponse {
  id: number;
  email: string;
  username: string;
  avatarUrl: string;
  steamId?: string;
  discordId?: string;
  createdAt: string;
  updatedAt: string;
  code?: number;
  message?: string;
  referalCode: string;
  permissions?: string[];
  status?: number;
  battlepasses?: {
    id: number;
    code: string;
    amount: 3000 | 5000 | 10000;
  }[];
}

export interface IReferralResponse {
  username: string;
  avatarUrl: string;
  code: number;
}

export interface IUserGeoResponse {
  range: number[];
  country: string;
  region: string;
  eu: string;
  timezone: string;
  city: string;
  ll: number[];
  metro: number;
  area: number;
}

export enum EUserPermissions {
  readPermissions = 'read_permissions',
  createRole = 'create_role',
  readRoles = 'read_roles',
  updateRole = 'update_role',
  deleteRole = 'delete_role',
  giveRole = 'give_role',
  createCycle = 'create_cycle_challenge',
  infiniteOwner = 'infinit_challenge_owner',
  infinitePart = 'infinit_challenge_participant',
}

export function registerUser(data: IUserParams): Promise<IUserResponse> {
  return fetch(`${BACKEND_URL}/api/user-service/auth/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

export function loginUser(data: IUserParams): Promise<IUserResponse> {
  return fetch(`${BACKEND_URL}/api/user-service/auth/login`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((data) => data.json());
}

export function getUserGeo(): Promise<IUserGeoResponse> {
  return fetch(`${BACKEND_URL}/api/user-service/users/geo`).then((data) =>
    data.json()
  );
}

export async function isLoginUser(): Promise<IUserResponse> {
  const res = await fetch(`${BACKEND_URL}/api/user-service/auth/login`, {
    credentials: 'include',
  });
  if (!res.ok) {
    const error = new Error('WalletError occured');
    const withStatus = { ...error, status: res.status };
    throw withStatus;
  }
  return res.json();
}

export function logoutUser(): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/user-service/auth/logout`, {
    method: 'POST',
    credentials: 'include',
  });
}

export function getReferralUser(code: string): Promise<Response> {
  return fetch(`${BACKEND_URL}/api/referal-service/referal?code=${code}`);
}
