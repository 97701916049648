import type { IUserResponse } from 'api';
import { Typography } from 'ui-kit';

import { useStyle } from '../../Profile.styles';

interface IProps {
  user: IUserResponse | null;
}

interface IIconProps {
  children: React.ReactNode;
  className: string;
}
const take = (arr: Array<any>, n = 1) => arr.slice(-n);

const BattlePassLine = ({ code, value }: { code: string; value: number }) => {
  const { Text } = Typography;
  const { classes } = useStyle();
  return (
    <div className={classes.battlepassInfoElement}>
      <Text variant="b5" block>
        {code}
      </Text>
      <Text variant="b5" color="typoPlaceholder" block>
        {value} руб
      </Text>
    </div>
  );
};

export function Battlepass({ user }: IProps): JSX.Element {
  if (!user) {
    return null;
  }

  const { Text } = Typography;

  return (
    <div
      style={{
        height: '100%',
      }}
    >
      <div>
        <Text variant="b6" color="typoPlaceholder" block>
          Промокоды на вывод средств
        </Text>
        {take(user.battlepasses ?? []).map((b) => (
          <BattlePassLine key={b.code} code={b.code} value={b.amount} />
        ))}
      </div>
    </div>
  );
}
