import React, { createContext, FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { deleteDraftChallenge, deleteDraftParry, isErrorResponse } from 'api';
import { EChallengeModals, EParryModals } from 'consts';
import { useCheckHistory } from 'hooks';

import {
  DeleteModal,
  ModalChallengeConfirmation,
  ModalCycle,
  GalleryModal,
  ModalReward,
  ModalSteam,
  BetaHintModal,
  ModalPredictionConfirmation,
  ModalMakeHistory,
  ModalLogin,
  WalletError,
  WalletSuccess,
  ManualDeposit,
  Withdraw,
  WithdrawFiat,
  ModalParryConfirmation,
  AboutProductModal,
  BattlepassModal,
} from './Modals';
import { CreateChallengePermission } from './Modals/CreateChallengePermission';

interface IModalProvider {
  children: React.ReactNode;
}

interface IModalContext {
  loginModal?: {
    openLogin: () => void;
  };
  steamModal?: {
    openSteam: () => void;
  };
  galleryModal?: {
    openGallery: () => void;
    selectedImage: string;
    resetImage: () => void;
    setImage: (string) => void;
  };
  historyModal?: {
    openHistory: () => void;
    isOpenModalHistory: boolean;
  };
  deleteModal?: {
    openDelete: (id: number) => void;
    needUpdateAfterDelete: boolean;
    cancelUpdate: () => void;
  };
  deleteParryModal?: {
    openDelete: (id: number) => void;
    needUpdateAfterDelete: boolean;
    cancelUpdate: () => void;
  };
  predictionConfirmationModal?: {
    openPredictionConfirmation: () => void;
    setPredictionValue: (value: number) => void;
    predictionValue: number;
  };
  rewardModal?: {
    openReward: () => void;
    setRewardStep: (step: number) => void;
  };
  cycleModal?: {
    openCycle: () => void;
    setCycleId: (id: number) => void;
  };
  challengeConfirmation?: {
    setChallengeId: (id: number) => void;
    setChallengeModalMode: (mode: EChallengeModals) => void;
    isChallengeConfirmationProcessing: boolean;
    setChallengeConfirmationValue: (value: number) => void;
  };
  parryConfirmation?: {
    setParryId: (id: number) => void;
    setParryWinning: (win: boolean) => void;
    setParryModalMode: (mode: EParryModals) => void;
    isParryConfirmationProcessing: boolean;
    setParryConfirmationValue: (value: number) => void;
  };
  betaHint?: {
    openBetaHint: () => void;
  };
  withdraw?: {
    openWithdraw: () => void;
  };
  withdrawFiat?: {
    openWithdrawFiat: () => void;
  };
  walletError?: {
    openWalletError: () => void;
  };
  walletSuccess?: {
    openWalletSuccess: () => void;
  };
  createPermission?: {
    openCreatePermission: () => void;
  };
  manualDeposit?: {
    openManualDeposit: () => void;
  };
  aboutProduct?: {
    openAboutProduct: () => void;
  };
  battlepass?: {
    openBattlepass: (s: string) => void;
  };
}

export const ModalContext = createContext<IModalContext>({});

export const ModalProvider: FC<IModalProvider> = ({ children }) => {
  const [isLoginOpen, setIsModalOpen] = useState(false);
  const openLogin = (): void => setIsModalOpen(true);
  const closeLogin = (): void => setIsModalOpen(false);

  const [isSteamOpen, setIsSteamOpen] = useState(false);
  const openSteam = (): void => setIsSteamOpen(true);
  const closeSteam = (): void => setIsSteamOpen(false);

  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const resetImage = (): void => setSelectedImage('');
  const openGallery = (): void => setIsGalleryOpen(true);
  const closeGallery = (): void => setIsGalleryOpen(false);
  const setImage = (image: string): void => setSelectedImage(image);

  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);
  const openHistory = (): void => setIsOpenModalHistory(true);
  const closeHistory = (): void => setIsOpenModalHistory(false);
  const { isRefetching, refetchGame } = useCheckHistory();

  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const closeDelete = (): void => setIsOpenDeleteModal(false);
  const [deleteDraftId, setDeleteDraftId] = useState(null);
  const openDelete = (id: number): void => {
    setDeleteDraftId(id);
    setIsOpenDeleteModal(true);
  };
  const [isDeleting, setIsDeleting] = useState(false);
  const [needUpdateAfterDelete, setNeedUpdateAfterDelete] = useState(false);
  const cancelUpdate = (): void => setNeedUpdateAfterDelete(false);
  const deleteChallenge = async (): Promise<void> => {
    try {
      setIsDeleting(true);
      const challenge = await deleteDraftChallenge(deleteDraftId);
      if (!isErrorResponse(challenge)) {
        setNeedUpdateAfterDelete(true);
        closeDelete();
      }
    } catch (err) {
      console.error('Failed challenge action', err);
    } finally {
      setIsDeleting(false);
    }
  };

  const [isOpenDeleteParryModal, setIsOpenDeleteParryModal] = useState(false);
  const closeDeleteParry = (): void => setIsOpenDeleteParryModal(false);
  const [deleteParryDraftId, setDeleteParryDraftId] = useState(null);
  const openDeleteParry = (id: number): void => {
    setDeleteParryDraftId(id);
    setIsOpenDeleteParryModal(true);
  };
  const [isDeletingParry, setIsDeletingParry] = useState(false);
  const [needUpdateAfterDeleteParry, setNeedUpdateAfterDeleteParry] =
    useState(false);
  const cancelUpdateParry = (): void => setNeedUpdateAfterDeleteParry(false);
  const deleteParry = async (): Promise<void> => {
    try {
      setIsDeletingParry(true);
      const parry = await deleteDraftParry(deleteParryDraftId);
      if (!isErrorResponse(parry)) {
        setNeedUpdateAfterDeleteParry(true);
        closeDeleteParry();
      }
    } catch (err) {
      console.error('Failed parry action', err);
    } finally {
      setIsDeletingParry(false);
    }
  };

  const [isPredictConfirmationOpen, setIsPredictConfirmationOpen] =
    useState(false);
  const closePredictionConfirmation = (): void =>
    setIsPredictConfirmationOpen(false);
  const openPredictionConfirmation = (): void =>
    setIsPredictConfirmationOpen(true);
  const [predictionValue, setPredictionValue] = useState(2500);

  const [isOpenRewardModal, setIsOpenRewardModal] = useState(false);
  const closeReward = (): void => setIsOpenRewardModal(false);
  const openReward = (): void => setIsOpenRewardModal(true);
  const [rewardStep, setRewardStep] = useState(null);

  const [isCycleOpen, setIsCycleOpen] = useState(false);
  const closeCycle = (): void => setIsCycleOpen(false);
  const openCycle = (): void => setIsCycleOpen(true);
  const [cycleId, setCycleId] = useState(null);
  const [isBattlepassOpen, setIsBattlepassOpen] = useState(false);
  const [battlepass, setBattlepass] = useState('');
  const openBattlepass = (promo: string) => {
    setBattlepass(promo);
    setIsBattlepassOpen(true);
  };
  const closeBattlepass = () => {
    setIsBattlepassOpen(false);
  };

  const [
    isChallengeConfirmationProcessing,
    setIsChallengeConfirmationProcessing,
  ] = useState(false);
  const [challengeConfirmationId, setChallengeConfirmationId] = useState(null);
  const [challengeConfirmationValue, setChallengeConfirmationValue] =
    useState(0);
  const [challengeConfirmationMode, setChallengeConfirmationMode] =
    useState(null);
  const setChallengeId = (id: number): void => setChallengeConfirmationId(id);
  const setChallengeModalMode = (mode: EChallengeModals): void =>
    setChallengeConfirmationMode(mode);
  const closeChallengeConfirmation = (): void =>
    setChallengeConfirmationMode(null);

  const [isParryConfirmationProcessing, setIsParryConfirmationProcessing] =
    useState(false);
  const [parryConfirmationId, setParryConfirmationId] = useState(null);
  const [parryWinning, setParryWinning] = useState(false);
  const [parryConfirmationValue, setParryConfirmationValue] = useState(0);
  const [parryConfirmationMode, setParryConfirmationMode] = useState(null);
  const setParryId = (id: number): void => setParryConfirmationId(id);
  const setParryModalMode = (mode: EParryModals): void =>
    setParryConfirmationMode(mode);
  const closeParryConfirmation = (): void => setParryConfirmationMode(null);

  const [isBetaHintOpen, setIsBetaHintOpen] = useState(false);
  const openBetaHint = (): void => setIsBetaHintOpen(true);
  const closeBetaHint = (): void => setIsBetaHintOpen(false);

  const [isWithdrawOpen, setIsWithdrawOpen] = useState<boolean>(false);
  const openWithdraw = (): void => setIsWithdrawOpen(true);
  const closeWithdraw = (): void => setIsWithdrawOpen(false);

  const [isWithdrawFiatOpen, setIsWithdrawFiatOpen] = useState<boolean>(false);
  const openWithdrawFiat = (): void => setIsWithdrawFiatOpen(true);
  const closeWithdrawFiat = (): void => setIsWithdrawFiatOpen(false);

  const [isWalletErrorOpen, setIsWalletErrorOpen] = useState<boolean>(false);
  const openWalletError = (): void => setIsWalletErrorOpen(true);
  const closeWalletError = (): void => setIsWalletErrorOpen(false);

  const [isWalletSuccessOpen, setIsWalletSuccessOpen] =
    useState<boolean>(false);
  const openWalletSuccess = (): void => setIsWalletSuccessOpen(true);
  const closeWalletSuccess = (): void => setIsWalletSuccessOpen(false);

  const [isCreatePermissionOpen, setIsCreatePermissionOpen] = useState(false);
  const openCreatePermission = (): void => setIsCreatePermissionOpen(true);
  const closeCreatePermission = (): void => setIsCreatePermissionOpen(false);

  const [isOpenManualDeposit, setIsOpenManualDeposit] =
    useState<boolean>(false);
  const openManualDeposit = (): void => setIsOpenManualDeposit(true);
  const closeManualDeposit = (): void => setIsOpenManualDeposit(false);

  const [isOpenAboutProduct, setIsOpenAboutProduct] = useState<boolean>(false);
  const openAboutProduct = (): void => setIsOpenAboutProduct(true);
  const closeAboutProduct = (): void => setIsOpenAboutProduct(false);

  const { t: translation } = useTranslation();

  return (
    <ModalContext.Provider
      value={{
        loginModal: { openLogin },
        steamModal: { openSteam },
        galleryModal: { openGallery, selectedImage, resetImage, setImage },
        historyModal: { openHistory, isOpenModalHistory },
        deleteModal: { openDelete, cancelUpdate, needUpdateAfterDelete },
        deleteParryModal: {
          openDelete: openDeleteParry,
          cancelUpdate: cancelUpdateParry,
          needUpdateAfterDelete: needUpdateAfterDeleteParry,
        },
        predictionConfirmationModal: {
          openPredictionConfirmation,
          setPredictionValue,
          predictionValue,
        },
        rewardModal: {
          openReward,
          setRewardStep,
        },
        cycleModal: {
          setCycleId,
          openCycle,
        },
        challengeConfirmation: {
          isChallengeConfirmationProcessing,
          setChallengeId,
          setChallengeModalMode,
          setChallengeConfirmationValue,
        },
        parryConfirmation: {
          isParryConfirmationProcessing,
          setParryId,
          setParryModalMode,
          setParryConfirmationValue,
          setParryWinning,
        },
        betaHint: {
          openBetaHint,
        },
        withdraw: {
          openWithdraw,
        },
        withdrawFiat: {
          openWithdrawFiat,
        },
        walletError: { openWalletError },
        walletSuccess: { openWalletSuccess },
        createPermission: { openCreatePermission },
        manualDeposit: { openManualDeposit },
        aboutProduct: { openAboutProduct },
        battlepass: { openBattlepass },
      }}
    >
      <ModalLogin isOpen={isLoginOpen} onClose={closeLogin} />
      <ModalSteam isOpen={isSteamOpen} onClose={closeSteam} />
      <GalleryModal
        isOpen={isGalleryOpen}
        onClose={closeGallery}
        setSelectedImage={setSelectedImage}
      />
      <ModalMakeHistory
        isOpen={isOpenModalHistory}
        onClose={closeHistory}
        onClick={refetchGame}
        isLoading={isRefetching}
      />
      <DeleteModal
        title={translation('challenges-list.delete-modal.title')}
        text={translation('challenges-list.delete-modal.description')}
        isOpen={isOpenDeleteModal}
        onClose={closeDelete}
        isSubmitting={isDeleting}
        onDeleteChallenge={deleteChallenge}
      />
      <DeleteModal
        title={translation('parries-list.delete-modal.title')}
        text={translation('parries-list.delete-modal.description')}
        isOpen={isOpenDeleteParryModal}
        onClose={closeDeleteParry}
        isSubmitting={isDeletingParry}
        onDeleteChallenge={deleteParry}
      />
      <ModalPredictionConfirmation
        confirmationValue={predictionValue}
        isOpen={isPredictConfirmationOpen}
        onClose={closePredictionConfirmation}
      />
      <ModalReward
        isOpen={isOpenRewardModal}
        onClose={closeReward}
        selectedStep={rewardStep}
      />
      <ModalCycle onClose={closeCycle} isOpen={isCycleOpen} id={cycleId} />
      <ModalChallengeConfirmation
        id={challengeConfirmationId}
        modalVisibleName={challengeConfirmationMode}
        setIsModalProcessing={setIsChallengeConfirmationProcessing}
        isModalProcessing={isChallengeConfirmationProcessing}
        onClose={closeChallengeConfirmation}
        confirmationValue={challengeConfirmationValue}
      />
      <ModalParryConfirmation
        id={parryConfirmationId}
        parryWinning={parryWinning}
        modalVisibleName={parryConfirmationMode}
        setIsModalProcessing={setIsParryConfirmationProcessing}
        isModalProcessing={isParryConfirmationProcessing}
        onClose={closeParryConfirmation}
        confirmationValue={parryConfirmationValue}
      />
      <BetaHintModal onClose={closeBetaHint} isOpen={isBetaHintOpen} />
      <Withdraw isOpen={isWithdrawOpen} onClose={closeWithdraw} />
      <WithdrawFiat isOpen={isWithdrawFiatOpen} onClose={closeWithdrawFiat} />
      <WalletError isOpen={isWalletErrorOpen} onClose={closeWalletError} />
      <WalletSuccess
        isOpen={isWalletSuccessOpen}
        onClose={closeWalletSuccess}
      />
      <CreateChallengePermission
        onClose={closeCreatePermission}
        isOpen={isCreatePermissionOpen}
      />
      <ManualDeposit
        isOpen={isOpenManualDeposit}
        onClose={closeManualDeposit}
      />
      <AboutProductModal
        isOpen={isOpenAboutProduct}
        onClose={closeAboutProduct}
      />
      <BattlepassModal
        isOpen={isBattlepassOpen}
        onClose={closeBattlepass}
        battlepass={battlepass}
      />
      {children}
    </ModalContext.Provider>
  );
};
