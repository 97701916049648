import { FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';

import { getTransactionCount, getUserGeo, isErrorResponse } from 'api';
import { BottomNotification, Card, Typography } from 'ui-kit';

import { useStyle } from '../Profile.styles';
import { Battlepass } from './Battlepass';
import { DiscordBanner } from './DiscordBanner';
import { Header } from './Header';
import { MoneyBanner } from './MoneyBanner';
import { Person } from './Person';
import { PromoCard } from './PromoCard';

export const Profile: FC = () => {
  const { data: user, error } = useSWR('api/user');
  const { classes } = useStyle();
  const navigate = useNavigate();

  useEffect(() => {
    if (error?.status === 403) {
      navigate('/home');
    }
  }, [error]);

  const { Text } = Typography;
  const { t: translation } = useTranslation();
  const { data: transactions } = useSWR(
    'api/transactions',
    getTransactionCount
  );
  const { data: geo } = useSWR('api/geo', getUserGeo);
  const isRussian = geo?.country === 'RU';
  return (
    <div className={classes.profileContainer}>
      <div className={classes.profileTopBlock}>
        <Card variant="p24" color="bgGhost" className={classes.userInfoBlock}>
          <Person user={!isErrorResponse(user) && user} />
        </Card>
        <PromoCard />
        <a
          href={
            window.location.origin +
            /*(geo?.country !== 'RU'
              ?*/ '/legal/world/Profile.TermsOfUse.pdf' /*
              : '/legal/ru/TermsOfUse.pdf')*/
          }
          target="_blank"
          rel="noreferrer"
        >
          <Text variant="b5" color="link" className={classes.terms}>
            {translation('profile.term')}
          </Text>
        </a>
      </div>
      <div className={classes.walletContainer}>
        <Header />
        {transactions?.count === 0 && <MoneyBanner />}
        <DiscordBanner />
      </div>
      <BottomNotification />
      {isRussian ? (
        <Card
          variant="p24"
          color="bgGhost"
          className={classes.battlepassInfoBlock}
        >
          <Battlepass user={!isErrorResponse(user) && user} />
        </Card>
      ) : null}
    </div>
  );
};
