import { makeStyles } from 'theme';

export const useStyle = makeStyles()((theme) => ({
  profileTopBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: 260,
  },
  input: {
    width: '100%',
  },
  userInfoBlock: {
    height: 332,
    width: 260,
    padding: '1.5rem',
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: '8px',
  },
  battlepassInfoBlock: {
    height: 250,
    width: 260,
    padding: '1.5rem',
    backgroundColor: theme.palette.bg.ghost,
    borderRadius: '8px',
  },
  battlepassInfoElement: {
    marginTop: '10px',
  },
  transactions: {
    height: 332,
    width: '78%',
    padding: '1.5rem',
    backgroundColor: theme.palette.bg.secondary,
    borderRadius: '8px',
  },
  profileBottomBlock: {
    marginBottom: '2rem',
    ...theme.layouts.flexJCSpaceBetween,
    flexWrap: 'wrap',
    rowGap: '1rem',
  },

  // Person Component

  personContainer: {
    ...theme.layouts.flexCenter,
    position: 'relative',
    height: 120,
    width: 120,
    border: `3px solid ${theme.palette.typo.link}`,
    marginBottom: '1rem',
    borderRadius: '6px',
  },

  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  avatar: {
    width: 72,
    height: 72,
  },

  userName: {
    overflow: 'hidden',
    marginBottom: '1rem',
    textOverflow: 'ellipsis',
  },

  userMail: {
    color: theme.palette.typo.primary,
    display: 'flex',
    marginBottom: '1.5rem',
  },

  mailIcon: {
    color: theme.palette.bg.border,
  },

  emailText: {
    marginLeft: '0.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  noEmailText: {
    marginLeft: '0.5rem',
  },

  linkIcon: {
    width: 40,
    color: theme.palette.typo.primary,
    height: 40,
    borderRadius: '8px',
    ...theme.layouts.flexCenter,
    backgroundColor: theme.palette.typo.link,
  },

  noLinkIcon: {
    width: 40,
    height: 40,
    borderRadius: '8px',
    marginLeft: '1rem',
    ...theme.layouts.flexCenter,
    color: theme.palette.bg.stripe,
  },
  // Promo Card

  promoBlock: {
    width: 260,
    flexDirection: 'column',
    borderRadius: '10px',
    padding: '2.75rem',
    paddingTop: '2.5rem',
    backgroundColor: theme.palette.bg.secondary,
  },
  promoTitle: {
    marginBottom: '2rem',
  },
  promoContainer: {
    position: 'relative',
  },
  promoError: {
    position: 'absolute',
    bottom: '4px',
  },
  promoBtn: {
    width: '100%',
  },
  promoSuccess: {
    position: 'absolute',
    bottom: '10px',
  },
  iconsContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
  },
  profileContainer: {
    display: 'flex',
    gap: 16,
    width: '100%',
    paddingBottom: 100,
  },
  terms: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    marginTop: 24,
  },
  walletContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
  },
  cardContainer: {
    display: 'flex',
    gap: 16,
  },
}));
